@mixin sm-viewport {
  @media (min-width: 48em) { @content; }
}
@mixin md-viewport {
  @media (min-width: 62em) { @content; }
}
@mixin lg-viewport {
  @media (min-width: 75em) { @content; }
}

::-webkit-scrollbar-track-piece:start {
    background: transparent url('/images/cursor.png') repeat-y !important;
}

::-webkit-scrollbar-track-piece:end {
    background: transparent url('/images/cursor.png') repeat-y !important;
}





.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


@for $i from 1 through 30 {
  .workgrid__item:nth-child(#{$i}) {
    grid-area: area-#{$i};
  }
}
            // "area-1 area-1 area-2 area-3"
            // "area-5 area-9 area-6 area-7"
            // "area-5 area-4 area-6 area-12"
            // "area-11 area-8 area-8 area-13"
            // "area-10 area-8 area-8 area-14"
            // "area-15 area-16 area-16 area-17"
.workgrid { 
    display: grid;
    margin: 0 auto;
    grid-gap: .3rem;
    grid-template-areas:
            "area-1 area-2"
            "area-3 area-6"
            "area-5 area-6"
            "area-5 area-4"
            "area-7 area-9"
            "area-8 area-8"
            "area-8 area-8"
            "area-11 area-10"
            "area-13 area-12"
            "area-16 area-16"
            "area-15 area-17"
}

.video {
    user-select: none;
}

.workgrid__item {
    padding-bottom: 80%;
}
.workgrid__item:nth-child(6), .workgrid__item:nth-child(5){
    padding-bottom: 160%;
}
.workgrid__item:nth-child(16){
    padding-bottom: 40%;
}
.workgrid__item:nth-child(3), .workgrid__item:nth-child(5), .workgrid__item:nth-child(4){
    padding-bottom: 80%;
}
.workgrid__item:nth-child(14){
    display: none;
}



.workgrid__item {
    position: relative;
    border-radius: 4px;
    background: var(--mono-tile);
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    min-height: 100px;
    overflow: hidden;
}
.workgrid__item img {
    max-width: none;
    width: 100%;
}
.workgrid__item__container, .workgrid__item__title, .workgrid__item a {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}
.workgrid__item__content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}
.workgrid__item--9 .workgrid__item__content {
    align-items: end;
}
.workgrid__item:hover a .workgrid__item__content  {
    filter: blur(44px);
    transform: scale(1.5);
}
.workgrid__item--watchface video {
    transform: scale(1.075);
}
.workgrid__item__title {
    line-height: var(--mono-line-height);
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    pointer-events: none;
    align-items: center;
    text-align: center;
    color: white;
    z-index: 10;
    display: flex;
    opacity: 0;
}
.workgrid__item a:hover {
    -webkit-animation: hueRotate 10s infinite;
    animation: hueRotate 10s infinite;
    .workgrid__item__title {
        opacity: 1;
    }
}



:root {
  --mono-blue: #008FFF;
  --mono-pink: #FFB3FF;
  --mono-red: #FF537A;
  --mono-yellow: #FFFFB5;
  --mono-green: #00FFB5;
  --mono-tile: #121217;
  --mono-gutter: 0.5rem;
  --mono-line-height: 1.65em;
  --mono-block-offset: 3.3em;
  cursor: none !important;
}

@keyframes hueRotate {
  from {filter: hue-rotate(0deg);}
  to {filter: hue-rotate(360deg);}
}

* {
  box-sizing: border-box;
}


html, body {
    font-family: 'IBM Plex Mono';
    background: black;
    margin: 0;
    padding: 0;
    height: 100%;
    cursor: none !important;
    font-size: 16px;
    color: white;
}

html::-webkit-scrollbar, body::-webkit-scrollbar, .words__carousel::-webkit-scrollbar { width: 0 }


.wrapper {
    position: relative;
}

html.is-locked, html.is-locked body, html.is-writing, html.is-writing body {
    overflow: hidden !important;
}


ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
}

section {
    max-width: 1280px;
    margin: 0 auto;
    line-height: var(--mono-line-height);
    font-size: 1.1rem;
    padding: 3.3em 1rem 3.3em 1rem;
    background-image: url("/images/grid-sm.png");
    background-size: contain;
    background-repeat: repeat-y;
    padding-bottom: 3em;
}

section.full {
    width: 100%;
    margin: 0;
    overflow: hidden;
    position: relative;
    background: none;
    max-width: none;
    padding: 0;
}

section.about {
    padding-bottom: 28rem;
}
section.about + .home-wrapper {
    margin-top: -28rem;
}

section > .visible-grid {
    max-width: 1024px;
    transform: translateX(-50%);
    background-image: url("/images/grid-sm.png");
    background-size: contain;
    background-repeat: repeat;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 50%;
}

.block {
    margin-top: var(--mono-line-height);
    margin-bottom: var(--mono-line-height);
}
.block--extended {
    margin-top: var(--mono-block-offset);
    margin-bottom: var(--mono-block-offset);
}

h3 {
    font-weight: normal;
    font-size: 1em;
    color: var(--mono-blue);
    margin: var(--mono-line-height) 0 0 0;
}

h3 + p {
    margin-top: 0.65em;
}

p.dropcap:first-letter {
    font-weight: bold;
    float: left;
    color: #FEFFAB;
    font-size: 3em;
    line-height: 0.9em;
    padding-top: 4px;
    padding-right: 15px;
    padding-left: 3px;
}

p a {
    text-decoration: underline;
}

dl, dt, dd {
    margin: 0;
    padding: 0;
}

dl, figcaption {
    line-height: var(--mono-line-height);
}

dl dd {
    opacity: 0.54;
}

figure {
    margin: 0;
    padding: 0;
}

h1, h2, h3 {
    display: inline-block;
}

h2 {
    color: var(--mono-blue);
    font-size: 1em;
    margin: 0 0 0.5em 0;
}

p {
    margin: var(--mono-line-height) 0 var(--mono-line-height) 0;
}

a {
    color: white;
    text-decoration: none;
}

hr {
    margin: calc(var(--mono-line-height) * 2) 0;
    border: none;
}
hr:after {
    display: block;
    content: '* * *';
    text-align: center;
}




.ambient {
    padding-bottom: 0;
    padding-top: 5em;
}
.ambient__cover {
  transition: all .5s ease-in-out;
  position: relative;
  z-index: 10;
  margin-top: -3vw;
  opacity: .85;
}

.ambient__cover:hover {
  opacity: 1;
}

.intro {
    background: none;
    min-height: 100vh;
    text-align: center;
    position: relative;
    padding: 0;
}

.nav {
    z-index: 1;
    position: relative;;
    padding: var(--mono-line-height) 1rem 4em 1rem;
}
.nav nav {
    position: relative;
}
.nav ul a{
    opacity: 0.54;
}
.nav ul a:hover {
    opacity: 1;
}
.nav ul {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
}
.nav ul li {
    display: inline-block;
}
.nav ul li {
    margin-left: 2em;
}

.footer{
    padding-top: var(--mono-line-height);
    padding-bottom: 0.25em;
    margin-left: -12px;
}
// .footer span:first-child {
//     display: none;
// }
.footer__content{
    padding: 0.5em 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer .footer__home {
    padding: 6px 16px;
}
.footer a {
    padding: 6px 12px;
    border-radius: 8px;
    display: inline-block;
}
.footer p {
    line-height: 1;
}
.footer__content span:after {
    content: '·';
}
.footer__content p:last-child {
    display: none;
}

.intro video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

h1 {
    /*font-size: 3.6rem;*/
    font-size: 2em;
    font-weight: normal;
    margin-bottom: 0em;
    line-height: 1.15em;
    margin-top: 0;
}

.about ~ section h1 {
    font-weight: 500;
}
.about {
    position: relative;
}
.about textarea {
    font-family: "IBM Plex Mono";
    line-height: 1.55em;
    z-index: 99;
    opacity: 0;
    overflow-y: scroll;
    font-size: 2em;
    pointer-events: none;
    outline: none;
    padding: 1.65em 1rem 1rem 1rem;
    background: none;
    font-weight: normal;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    bottom: 72px;
    font-size: 1em;
    color: #fff;
    border: none;
}

video {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    display: block;
    margin: 0 auto;
}


p {
    line-height: var(--mono-line-height);
}

.about ul {
    margin: 3em 0 0 0;
}
.about li {
    margin-bottom: 2em;
    align-self: baseline;
}
.about img {
    display: block;
    margin-bottom: 1.5em;
}

img {
    max-width: 100%;
    vertical-align: bottom;
}

.row.row--extend {
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    .round-clipped img{
        border-radius: 0px;
    }
}

.col-xs-hide-inline { display: none; }
.col-xs-hide { display: none; }
/* tablets */
@include sm-viewport {
.col-xs-hide { display: block; }
.col-xs-hide-inline { display: inline; }
.col-sm-hide { display: none; }
.row.row--extend .round-clipped img, .videoWrapper iframe {
    border-radius: 12px;
    }
}

/* desk */
@include md-viewport {
.col-sm-hide { display: block; }
.col-sm-hide-inline { display: block; }
.col-md-hide { display: none; }
.row.row--extend {
    margin-left: -10%;
    margin-right: -10%;

        .round-clipped img {
            border-radius: 12px;
        }
    }
}

@include lg-viewport  {
.col-lg-hide { display: none; }
}

.words {
    position: relative;
    margin-top: 2em;
    overflow: hidden;
    padding-bottom: 0;
}
.words__drawer {
    margin: 4em 0;
    height: 0;
    overflow-y: hidden;
    overflow-x: hidden;
    overflow: hidden;
}
.words__samples {
    width: 243vw;
    margin-top: 2em;
    margin-bottom: 2em;
}
.words__samples img {
    width: 8em;
}
.words__samples__item {
    margin-bottom: var(--mono-line-height);
}
.words__extended {
    padding: 1.5em 0;
}
.words__extended li {
    margin: var(--mono-line-height) 0;
}
.words__carousel {
    overflow-x: scroll;
    margin-left: -5%;
    margin-right: -5%;
}
.words__carousel > * {
    margin-left: 1rem;
    margin-right: 1rem;
}
.words figcaption {
    margin-top: 1em;
}
.words__extended {
    padding: 0 0 2em 0;
}
.words__drawer {
    margin-top: 2em;
}
.words figcaption {
    margin-top: 1.5em;
    margin-right: 24px;
    margin-left: 0.5rem;
}




.about__buttons {
    margin-top: 2em;
    margin-bottom: 3.5em;
}

/*
***************
* ABOUT
***************
*/
.home-wrapper, .btn__icon, .about__buttons {
    transition: all .8s cubic-bezier(0.230, 1.000, 0.320, 1.000);
}
.about__title, .about__introText, .btn--snd{
    transition: all .65s cubic-bezier(0.230, 1.000, 0.320, 1.000);
}
@include sm-viewport {
    .is-writing .btn__icon {
        transform: rotate(180deg) translateY(24%);
    }
}

.about textarea {
    transition: all .8s cubic-bezier(0.230, 1.000, 0.320, 1.000);
    transform: translateY(44px);
}
.has-content textarea {
    transition: all .3s cubic-bezier(0.230, 1.000, 0.320, 1.000);
}

.btn__text {
    position: relative;
}
.btn__text__sub, .btn__text__main {
    transition: all .3s ease-in-out;
}
.btn__text__sub {
    opacity: 0;
}
.btn__text__sub {
    position: absolute;
    width: 200px;
    top: 0;
    left: 0;
}
.btn--snd {
    transform: translateY(48px);
    pointer-events: none;
    opacity: 0;
}
.is-writing textarea {
    max-height: 50vh;
    pointer-events: auto;
    transform: translateY(0px);
    opacity: 1;
}
.is-writing .btn__text__sub {
    opacity: 1;
}
.is-writing .btn__text__main {
    opacity: 0;
}
.is-writing .about .btn--hi {
    padding-right: 1.8em;
}
.is-writing .about__introText, .is-writing .about__title {
    transform: translateY(-44px);;
    opacity: 0;
}
.is-writing .btn__icon{
    transform: rotate(180deg);
}
.has-content.is-writing .btn--snd {
    pointer-events: auto;
    transform: translateY(0px);
    opacity: 1;
}



/*
***************
* PLAY
***************
*/
.play {
    position: relative;
    background: #000;
    padding-bottom: 240vw;
}
.play__visual {
    transform: scale(2.2) translateX(-16%) translateY(33vh);
    overflow: hidden;
}
.play__content {
    position: relative;
    margin-top: -60%;
    z-index: 10;
    top: 0;
}
.play__container {
    overflow: hidden;
    position: absolute;
    height: 100%;
}
.fab {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -6.5em;
    left: 50%;
    transform: translate(-50%, 50%);
    background: var(--mono-tile);
    position: absolute;
    border-radius: 100%;
    width: 72px;
    height: 72px;
}
.fab--toggled:not(:hover) img{
    transition: transform 0.5s;
}
.fab--toggled img{
    transform: rotate(45deg);
}
.fab img {
    width: 48px;
    height: 48px
}


/*
***************
* SPEAKING
***************
*/
.speaking {
    position: relative;
    z-index: 10;
    padding-bottom: 0;
}
.speaking__figure span {
    justify-content: center;
    padding: 1.5em 0;
    height: auto;
}
.speaking__figure__image {
    max-width: 72px;
}
.speaking .engagement-list {
    margin-top: 3em;
}
.speaking .engagement-list__item {
    margin-bottom: 1em;
}

/* tablets */
@include sm-viewport {
    .workgrid {
        display: grid;
        margin: 0 auto;
        grid-gap: .3rem;
        grid-template-areas:
                "area-1 area-1 area-2 area-3"
                "area-5 area-9 area-6 area-7"
                "area-5 area-4 area-6 area-12"
                "area-11 area-8 area-8 area-13"
                "area-10 area-8 area-8 area-14"
                "area-15 area-16 area-16 area-17"
    }

    .workgrid__item:nth-child(1), .workgrid__item:nth-child(2), .workgrid__item:nth-child(3), .workgrid__item:nth-child(4), .workgrid__item:nth-child(7), .workgrid__item:nth-child(9), .workgrid__item:nth-child(10), .workgrid__item:nth-child(11), .workgrid__item:nth-child(12), .workgrid__item:nth-child(13), .workgrid__item:nth-child(14), .workgrid__item:nth-child(15), .workgrid__item:nth-child(16), .workgrid__item:nth-child(17){
        padding-bottom: 40%;
    }
    .workgrid__item:nth-child(5), .workgrid__item:nth-child(4), .workgrid__item:nth-child(9), .workgrid__item:nth-child(7), .workgrid__item:nth-child(12), .workgrid__item:nth-child(6), .workgrid__item:nth-child(8) {
        padding-bottom: 80%;
    }
    .workgrid__item:nth-child(14){
        display: block;
    }

    hr {

    }
    .play__container {
        position: static;
        overflow: visible;
    }
    h1 {
        font-size: 3.3em;
    }
    body {
        display: block;
    }
    .about textarea {
        padding-left: 0;
        padding-right: 1rem;
    }
    .words__carousel {
        overflow: auto;
    }
    .words__samples {
        width: auto;
    }
    .words__samples img {
        width:  6em;
    }

    .play__visual {
        transform: scale(1.2);
        margin-top: 5em;
        opacity: 1;
    }
    .play__content {
        position: absolute;
        margin-top: 4em;
    }
    .play {
        padding-bottom: 8em;
    }
    .work__item--tall {
        height: 72vw;
    }
    .footer {
        margin-left: 5%;
    }
    .nav {
        padding-left: 0;
        padding-right: 0;
    }
    .footer span:first-child {
        display: inline-block;
    }
    .footer__content p:last-child {
        display: block;
    }
    section {
        background-image: url("/images/grid.png");
        margin-left: 5%;
        margin-right: 5%;
        padding-left: 0;
        padding-right: 0;
    }
}

                // "area-1 area-1 area-2 area-3 area-4 area-5"
                // "area-6 area-7 area-8 area-8 area-9 area-5"
                // "area-6 area-10 area-8 area-8 area-11 area-12"
                // "area-13 area-14 area-15 area-16 area-16 area-17"

/* md */
@include md-viewport {

    html, body {
        font-size: 20px;
    }

    h1 {
      font-size: 2em;
    }

    .words__carousel, .words__carousel__content{
        margin-left: 0;
        margin-right: 0;
    }


    video {
        width: 100%;
    }
    section {
        margin-left: 10%;
        margin-right: 10%;
        padding-left: 0%;
        padding-right: 0%;
    }
    .footer {
        margin-left: 10%;
    }

    .work__item {
        height: calc((1.5*0.1604166667) * 97vw * 0.8376623377);
    }
    .work__item--tall {
        height: calc((3*0.1604166667) * 97vw * 0.8376623377);
        margin-bottom: 0;
    }
    .col-md-pullup-1 {
        margin-top: calc((-1.5*0.1604166667) * 97vw * 0.8376623377);
    }
    .col-md-pullup-2 {
        margin-top: calc((-3*0.1604166667) * 97vw * 0.8376623377);
    }

/*    .work__item:empty {
        display: none;
    }*/

    .footer {
        display: block;
    }

    .col-md-pull-left-1 {
        margin-left: -25%;
    }
    .col-md-pull-left-2{
        margin-left: -50%;
    }
    .col-md-pull-left-3{
        margin-left: -75%;
    }
    .col-md-push-right-1{
        margin-left: 25%;
    }
    .col-md-push-right-2{
        margin-left: 50%;
    }
    .col-md-push-right-3{
        margin-left: 75%;
    }
    .col-md-hide {
        display: none;
    }



    .work__item:not(.work__item--m):hover {
        animation: hueRotate 10s infinite;
    }

    .work__item:hover .work__item__container__content {
        filter: blur(44px);
        transform: scale(1.5);
    }
    .work__item:hover .work__item__container video {
        transform: translate(-50%, -20%);
    }
    .work__item:hover .work__item__container__description {
        opacity: 1;
    }
    .work__item--ambient:hover .work__item__container__content, .work__item__discovery:hover .work__item__container__content, .work__item--goo:hover .work__item__container__content, .work__item--jenny:hover .work__item__container__content {
        filter: blur(14px);
    }

    .work__item--nga:hover .work__item__container__content, .work__item--tactile:hover .work__item__container__content {
        filter: blur(24px);
        transform: scale(1);
    }
}



@include lg-viewport {
    .workgrid {
        display: grid;
        margin: 0 auto;
        grid-gap: .3rem;
        grid-template-areas:
                "area-1 area-1 area-2 area-3 area-4 area-5"
                "area-6 area-7 area-8 area-8 area-9 area-5"
                "area-6 area-10 area-8 area-8 area-11 area-12"
                "area-13 area-14 area-15 area-16 area-16 area-17"
    }

    .workgrid__item:nth-child(1), .workgrid__item:nth-child(2), .workgrid__item:nth-child(3), .workgrid__item:nth-child(4), .workgrid__item:nth-child(7), .workgrid__item:nth-child(9), .workgrid__item:nth-child(10), .workgrid__item:nth-child(11), .workgrid__item:nth-child(12), .workgrid__item:nth-child(13), .workgrid__item:nth-child(14), .workgrid__item:nth-child(15), .workgrid__item:nth-child(16), .workgrid__item:nth-child(17){
        padding-bottom: 40%;
    }
    .workgrid__item:nth-child(5), .workgrid__item:nth-child(6), .workgrid__item:nth-child(8) {
        padding-bottom: 80%;
    }

    figure.offset {
        margin-left: -12.5%;
        margin-right: -12.5%;
    }

    h1 {
      font-size: 3em;
    }
    h1 + .row {
        margin-top: -0.75em;
    }
    .about h1 + .row {
        margin-top: 0;
    }
    section {
        width: 58.5%;
        max-width: 1140px;
        font-size: min(1.2vw, 24px);
        padding-left: 0;
        padding-right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .footer {
        margin-left: auto;
    }

    .work__item {
        height: calc((0.1604166667) * 97vw * 0.8376623377);
    }

    .work__item--tall {
        height: calc(2 * 0.1604166667 * 97vw * 0.8376623377);
        margin-bottom: calc(-0.1604166667 * 97vw * 0.8376623377);
    }

    .col-md-pull-left-1, .col-md-pull-left-2, .col-md-pull-left-3, .col-md-push-right-1, .col-md-push-right-2, .col-md-push-right-3{
        margin-left: 0;
    }


    .col-md-hide {
        display: block;
    }

    .col-lg-hide {
        display: none;
    }

    .col-md-pullup-1, .col-md-pullup-2 {
        margin-top: 0;
    }
}



@import 'modules/flexboxgrid/flexboxgrid';
@import 'modules/btn/btn';
@import 'casestudy';
@import 'modules/color-picker/color-picker';
@import 'modules/cursor/cursor';
@import 'modules/mood/mood';
@import 'modules/tile/tile';
@import 'experiments';
